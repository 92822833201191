import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const PersonalInformation = Loadable(
  lazy(() => import('views/common/profile/personalInformation'))
);

const SecurityAndNotifications = Loadable(
  lazy(() => import('views/common/profile/securityAndNotifications'))
);

const ProfileRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/p/personal-information',
      element: <PersonalInformation />,
    },
    {
      path: '/p/security-and-notifications',
      element: <SecurityAndNotifications />,
    },
  ],
};

export default ProfileRoutes;
