// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  userList: [],
  total: 0,
  userType: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getUserSuccess(state, action) {
      state.userList = action.payload.items;
      state.totalPage = action.payload.total;
    },
    getUserTypeSuccess(state, action) {
      state.userType = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { logoutSuccess, getUserSuccess, hasError } = slice.actions;

export function getUserList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/v1/user/list`, payload);
      dispatch(getUserSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function getUserType(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/app/v1/auth/userType', payload);
      dispatch(slice.actions.getUserTypeSuccess(response.data.data));
      return response.data;
    } catch (error) {
      // ...
      throw error;
    }
  };
}
export function deleteUser(userId) {
  return async () => {
    try {
      const response = await axios.delete(`/api/v1/user/${userId}`);
      return response.data.data;
    } catch (error) {}
  };
}
