/* eslint-disable react/prop-types */
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Button } from "@mui/material";
import AnimateButton from "ui-component/extended/AnimateButton";

const SecondaryButton = (props) => {
  return (
    <>
      <AnimateButton>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          {...props}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: 'secondary.dark',
              cursor: 'not-allowed !important',
            },
          }}
        >
          {props?.isLoader ? (
            <Box
              sx={{
                mx: 4,
              }}
            >
              <CircularProgress size={24} color="black" />
            </Box>
          ) : (
            props.title
          )}{' '}
        </Button>
      </AnimateButton>
    </>
  );
};

export default SecondaryButton;
