// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  fileResponse: '',
};

const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    uploadFileResponse(state, action) {
      state.fileResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { uploadFileResponse } = slice.actions;

// ----------------------------------------------------------------------

export function uploadFile(formData, extension) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/app/v1/file/${extension}`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
