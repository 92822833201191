// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  userType: [],
  userSubType: [],
  registerResponse: {},
  updateProfileResponse: {},
  profile: {},
  changePasswordResponse: {},
  forgotPasswordResponse: {},
  verifyForgotOTPResponse: {},
  resetPasswordResponse: {},
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD PRODUCT
    loginSuccess(state, action) {
      state.authData = action.payload;
    },
    logoutSuccess(state, action) {
      state.authData = {};
    },
    registerSuccess(state, action) {
      state.registerResponse = action.payload;
    },
    updateProfileSuccess(state, action) {
      state.updateProfileResponse = action.payload;
    },
    getProfileSuccess(state, action) {
      state.profile = action.payload;
    },
    changePasswordSuccess(state, action) {
      state.changePasswordResponse = action.payload;
    },
    forgotPasswordSuccess(state, action) {
      state.forgotPasswordResponse = action.payload;
    },
    verifyForgotOTPSuccess(state, action) {
      state.verifyForgotOTPResponse = action.payload;
    },
    resetPasswordSuccess(state, action) {
      state.resetPasswordResponse = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { logoutSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function login(payload) {
  return async (dispatch) => {
    // <-- Add dispatch as an argument here
    try {
      const response = await axios.post('/api/v1/auth/login', payload);
      dispatch(slice.actions.loginSuccess(response.data));
      localStorage.setItem('token', response.data?.data?.token);
      return response.data; // <-- This will be returned by dispatch(login(credentials))
    } catch (error) {
      // ...
      throw error; // <-- This will cause dispatch(login(credentials)) to reject
    }
  };
}
export function register(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/auth/register/otp', payload);
      dispatch(
        slice.actions.registerSuccess({
          ...response.data.data.tokeId,
          email: payload.email,
        })
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function verifyOTP(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/auth/register', payload);
      dispatch(slice.actions.registerSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function updateProfile(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.put('/app/v1/auth/profile', payload);
      dispatch(slice.actions.updateProfileSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function getProfile(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/v1/auth/profile', payload);
      dispatch(slice.actions.getProfileSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function changePassword(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/auth/changePassword', payload);
      dispatch(slice.actions.changePasswordSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function forgotPassword(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/app/v1/auth/forgot-password',
        payload
      );
      dispatch(slice.actions.forgotPasswordSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function verifyForgotOTP(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        '/app/v1/auth/forgotOtp/verify',
        payload
      );
      dispatch(slice.actions.verifyForgotOTPSuccess(response.data.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export function resetPassword(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/app/v1/auth/reset-password', payload);
      dispatch(slice.actions.resetPasswordSuccess(response.data.data)); // Update the action name to resetPasswordSuccess
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
