import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// initial state
const initialState = {
  addProjectResponse: {},
  projectDetails: {},
  setPreferenceResponse: {},
  preferences: {}, // Ensure preferences is an object if it should store key-value pairs
  masterData: {},
  projectList: [],
  totalPage: 0,
  error: null, // Ensure error is part of the initial state if used
  projectLink: {},
  updateProjectResponse: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    addProjectSuccess(state, action) {
      state.addProjectResponse = action.payload;
    },
    projectDetailsSuccess(state, action) {
      state.projectDetails = action.payload;
    },
    updateProjectSuccess(state, action) {
      state.updateProjectResponse = action.payload;
    },

    getProjectListSuccess(state, action) {
      state.projectList = action.payload.items;
      state.totalPage = action.payload.total;
    },
  },
});

export const {
  hasError,
  addProjectSuccess,
  getProjectListSuccess,
  projectDetailsSuccess,
  updateProjectSuccess,
} = projectSlice.actions;

export default projectSlice.reducer;
export function getProjectDetails(projectName) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/v1/project/${projectName}`);
      dispatch(projectDetailsSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getProjectList(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/v1/project/list`, payload);
      dispatch(getProjectListSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
export function updateProject(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/api/v1/project/${payload.id}`,
        payload
      );
      dispatch(updateProjectSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deleteProject(projectId) {
  return async () => {
    try {
      const response = await axios.delete(`/api/v1/project/${projectId}`);
      return response.data;
    } catch (error) {}
  };
}
