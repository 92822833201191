// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { VscHome } from 'react-icons/vsc';
import { CiStar } from 'react-icons/ci';
import { SlSettings } from 'react-icons/sl';
import { MdOutlinePeopleAlt } from 'react-icons/md';

import { IoShareSocialSharp } from 'react-icons/io5';
import { IoIosLock } from 'react-icons/io';
import { FaCreditCard } from 'react-icons/fa';

import { ReactComponent as PersonalInfoIcon } from 'assets/images/svg/personal-info.svg';

const icons = {
  VscHome,
  SlSettings,
  MdOutlinePeopleAlt,
  CiStar,
  PersonalInfoIcon,
  IoShareSocialSharp,
  IoIosLock,
  FaCreditCard,
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      icon: icons.IconDashboard,
      type: 'group',
      children: [
        {
          id: 'personalInformation',
          title: <FormattedMessage id="Personal Information" />,
          type: 'item',
          url: '/p/personal-information',
          icon: icons.PersonalInfoIcon,
          prefix: ['p'],
          access: [3],
        },
        {
          id: 'securityAndNotifications',
          title: <FormattedMessage id="Security And Notifications" />,
          type: 'item',
          url: '/p/security-and-notifications',
          icon: icons.IoIosLock,
          prefix: ['p'],
          access: [3],
        },
        {
          id: 'allProject',
          title: <FormattedMessage id="Projects" />,
          type: 'item',
          url: '/a/project',
          icon: icons.SlSettings,
          access: [3],
          prefix: ['a'],
        },
        {
          id: 'allUsers',
          title: <FormattedMessage id="Users" />,
          type: 'item',
          url: '/a/user',
          icon: icons.MdOutlinePeopleAlt,
          access: [3],
          prefix: ['a'],
        },
        {
          id: 'resources',
          title: <FormattedMessage id="Resources" />,
          type: 'item',
          url: '/a/resource',
          icon: icons.MdOutlinePeopleAlt,
          access: [3],
          prefix: ['a'],
        },
      ],
    },
  ],
};

export default menuItems;
