import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'utils/axios';

// initial state
const initialState = {
  currentStep: 0,
};

// ==============================|| SLICE - MENU ||============================== //

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    activeFormStep(state, action) {
      state.currentStep = action.payload;
    },
  },
});

export default common.reducer;

export const { activeFormStep } = common.actions;
