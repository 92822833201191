import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { useSelector } from 'react-redux';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const { authData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const redirect = () => {
    if (authData?.data?.token) {
      if (authData.data.userType.userTypeId === 3) {
        navigate('/a/project');
      }
    } 
  };
  return (
    <Link component={RouterLink} to={redirect} aria-label="Prodculator">
      <Logo />
    </Link>
  );
};

export default LogoSection;
