import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'utils/axios';
import { openSnackbar } from './snackbar';

// initial state
const initialState = {
  taxRebateInfo: {},
  subscribeRepose: {},
  eligibleRepose: {},
};

// ==============================|| SLICE - MENU ||============================== //

const project = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getTaxRebateSuccess(state, action) {
      state.taxRebateInfo = action.payload;
    },
    postSubscribeSuccess(state, action) {
      state.subscribeRepose = action.payload;
    },
    getEligibleSuccess(state, action) {
      state.eligibleRepose = action.payload;
    },
  },
});

export default project.reducer;

export function getTaxRebate(payload) {
  return async () => {
    try {
      const response = await axios.post(
        `/app/v1/calculate/tax-rebate/${payload?.countryCode}`,
        payload
      );
      dispatch(project.actions.getTaxRebateSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(project.actions.hasError(error));
      throw error;
    }
  };
}
export function postSubscribe(payload) {
  return async () => {
    try {
      const response = await axios.post(
        `/app/v1/calculate/subscribe/${payload.countryCode}/${payload.taxId}`,
        payload
      );
      dispatch(project.actions.postSubscribeSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(project.actions.hasError(error));
      throw error;
    }
  };
}
export function getEligible(payload) {
  return async () => {
    try {
      const response = await axios.post(`/app/v1/calculate/eligible`, payload);
      dispatch(project.actions.getEligibleSuccess(response.data.data));
      return response.data;
    } catch (error) {
      dispatch(project.actions.hasError(error));
      throw error;
    }
  };
}
